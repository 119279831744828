import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService } from '../feedback.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss']
})
export class SendFeedbackComponent implements OnInit {

  loading$ = new BehaviorSubject(false);

  feedbackForm: FormGroup = new FormGroup({});

  constructor(
    private snackbar: MatSnackBar,
    private feedbackAPI: FeedbackService,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<FeedbackDialogComponent>
  ) { }

  ngOnInit() {
    this.feedbackForm = this.fb.group({
      message: ['', Validators.required]
    });
  }

  sendFeedback(data: any) {
    if (!this.feedbackForm.valid) {
      return;
    }

    this.feedbackForm.disable();

    this.loading$.next(true);

    this.feedbackAPI.sendFeedback(data).pipe(take(1)).subscribe(_feedback => {
      this.loading$.next(false);

      this.feedbackForm.enable();

      if (!_feedback.success) {
        this.snackbar.open('Kunde inte skicka feedback.', 'OK', {
          duration: 10000
        });

        return;
      }

      this.snackbar.open(_feedback.message, 'OK', {
        duration: 3000
      });

      this.router.navigate(['/', {
        outlets: {
          help: null
        }
      }]);
    }, (err) => {
      this.snackbar.open('Ett fel uppstod när feedbacken skulle skickas.', 'OK', {
        duration: 10000
      });

      this.loading$.next(false);

      this.feedbackForm.enable();

      throw err;
    });
  }

}
