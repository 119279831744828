import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized, NavigationEnd } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';

interface PageOptions {
  canGoBack?: boolean;
  pageTitle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarService implements OnDestroy {

  public canGoBack = false;
  public pageTitle$ = new BehaviorSubject('');

  sub$ = new Subject();

  constructor(
    private router: Router,
    private location: Location,
    private titleService: Title
  ) {
    this.router.events.pipe(takeUntil(this.sub$), filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        let root = this.router.routerState.snapshot.root;
        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data) {
            const _title = root.data['title'];
            const _canGoBack = root.data['canGoBack'];

            if (_title) {
              this.setTitle(_title);

              this.pageTitle$.next(_title);
            }

            if (!!_canGoBack) {
              this.canGoBack = _canGoBack;
            } else {
              this.canGoBack = false;
            }

            return;
          } else {
            return;
          }
        }
      });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(`${environment.appName} | ${newTitle}`);
  }

  public registerPage(_options: PageOptions) {
    if (!!_options.canGoBack) {
      this.canGoBack = _options.canGoBack;
    }

    if (_options.pageTitle) {
      this.pageTitle$.next(_options.pageTitle);
    }
  }

  public goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.sub$.next();
    this.sub$.complete();
  }
}
