<app-feedback-toolbar [back]="true" [title]="(tutorial$ | async)?.title"></app-feedback-toolbar>

<mat-dialog-content>
  <ng-container *ngIf="(tutorial$ | async) as tutorial; else loadingTutorialTemplate">
    <div fxLayout="column">
      <video class="video" controls *ngIf="tutorial.asset">
        <source [src]="tutorial.asset.url">
      </video>

      <ng-container *ngIf="tutorial.content">
        <div class="tutorial-content" [innerHtml]="tutorial.content"></div>
      </ng-container>

      <ng-container *ngIf="!tutorial.video && !tutorial.content">
        <div class="tutorial-content">
          <mat-error>
            Denna tutorialen har inget innehåll...
          </mat-error>
        </div>
      </ng-container>
    </div>
  </ng-container>
</mat-dialog-content>

<ng-template #loadingTutorialTemplate>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
