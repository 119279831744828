import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-feedback-toolbar',
  templateUrl: './feedback-toolbar.component.html',
  styleUrls: ['./feedback-toolbar.component.scss']
})
export class FeedbackToolbarComponent implements OnInit {

  @Input() title = 'Kunskapsbank';
  @Input() back = false;
  @Input() content = false;

  constructor() { }

  ngOnInit() {
  }

}
