import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, shareReplay, debounceTime, startWith, switchMap, catchError, take, finalize } from 'rxjs/operators';
import { ErrandsService } from '@app/errands/errands.service';
import { IErrand } from '@app/errands/errand';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { handleApiResponse } from '@app/services/api.service';

@Component({
  selector: 'app-errand-selector-dialog',
  templateUrl: './errand-selector-dialog.component.html',
  styleUrls: ['./errand-selector-dialog.component.scss']
})
export class ErrandSelectorDialogComponent implements AfterViewInit {

  searchForm = new FormGroup({
    search: new FormControl('', Validators.nullValidator)
  });

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  errands$: Observable<IErrand[]>;

  selectedErrand: IErrand;

  constructor(
    private errandsService: ErrandsService
  ) { }

  ngAfterViewInit(): void {
    this.getErrands();
  }

  getErrands() {
    this.errands$ = this.searchForm.get('search').valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      switchMap((search) => {
        this.loading$.next(true);

        return this.errandsService.getUserErrands({
          search
        }).pipe(
          handleApiResponse<IErrand[]>('errands'),
          catchError(() => {
            this.loading$.next(false);

            return of([]);
          }),
          finalize(() => this.loading$.next(false))
        );
      }),
    );
  }

}
