import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  public isPrinting$ = new BehaviorSubject(false);

  constructor() { }

  setPrinting(status: boolean) {
    this.isPrinting$.next(status);
  }

}
