import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { TutorialService } from '@app/tutorial/tutorial.service';
import { takeUntil, take, debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { handleApiResponse } from '@app/services/api.service';
import { ITutorial } from '@app/tutorial/tutorial.model';

@Component({
  selector: 'app-feedback-start',
  templateUrl: './feedback-start.component.html',
  styleUrls: ['./feedback-start.component.scss']
})
export class FeedbackStartComponent implements OnInit, OnDestroy {

  searchControl: FormControl = new FormControl();

  filteredResults$ = new BehaviorSubject([]);

  searchVal: string;

  feedbackMode = false;

  sub$ = new Subject();

  constructor(
    private tutorialAPI: TutorialService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  getTutorials() {
    this.tutorialAPI.getTutorials({
      limit: '3'
    }).pipe(
      handleApiResponse<ITutorial[]>('tutorials')
    ).subscribe((tutorials) => {
      this.filteredResults$.next(tutorials);
    });
  }

  ngOnInit() {
    this.getTutorials();

    this.searchControl.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.sub$)
    ).subscribe({
      next: (_search) => {
        this.search(_search);
      }
    });
  }

  search(val: string) {
    if (!val) {
      this.getTutorials();

      return;
    }

    const searchString = val.trim().toLowerCase();

    this.tutorialAPI.getTutorials({
      limit: '10',
      search: searchString
    }).pipe(
      handleApiResponse<ITutorial[]>('tutorials')
    ).subscribe((tutorials) => {
      this.filteredResults$.next(tutorials);
    });
  }

  navigateInFeedback(route: string) {
    this.router.navigate([{
      outlets: {
        help: route
      }
    }], {
      relativeTo: this.route
    });
  }

  ngOnDestroy(): void {
    this.sub$.next();

    this.sub$.complete();
  }

}
