import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '@app/utility/customInput';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  providers: [
    MakeProvider(SearchFieldComponent)
  ]
})
export class SearchFieldComponent extends AbstractValueAccessor<string> implements OnInit {

  @Input() noExpand = false;

  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
  }

  onClear() {
    this.writeValue(undefined);

    this.reset.next();
  }

}
