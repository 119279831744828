import { Component, OnInit, OnDestroy } from '@angular/core';
import { TutorialService } from '@app/tutorial/tutorial.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, take, map, catchError } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { ITutorial } from '@app/tutorial/tutorial.model';
import { handleApiResponse } from '@app/services/api.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

  sub$ = new Subject<void>();

  tutorial$ = new Observable<ITutorial>();

  constructor(
    private route: ActivatedRoute,
    private tutorialAPI: TutorialService,
  ) { }

  loadTutorial(tutorialId: string) {
    this.tutorial$ = this.tutorialAPI.getTutorial(tutorialId).pipe(
      handleApiResponse<ITutorial>('tutorial')
    );
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.sub$)).subscribe(_params => {
      const tutorialId = _params.get('tutorialId');

      if (tutorialId) {
        this.loadTutorial(tutorialId);
      }
    });
  }

  ngOnDestroy() {
    this.sub$.next();

    this.sub$.complete();
  }

}
