import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpHandler, HttpRequest, HttpEvent
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@app/auth/services/auth.service';
import { throwError, Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class InvalidLoginInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => {
            if (!environment.interceptorWhitelist.includes(err.url)) {
                if (err.status === 401) {
                    this.auth.logout();
                    location.reload();
                }
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
