<mat-sidenav-container fxFlex>
    <mat-sidenav #notificationSidenav position="end"
        [opened]="(media.registerQuery('gt-lg') | async) && (auth.loggedIn | async) && !(printService.isPrinting$ | async)"
        [mode]="(media.registerQuery('gt-lg') | async) ? 'side' : 'over'" class="notifications-sidenav mat-elevation-z4"
        ngClass.xs="mobile">
        <div fxLayout="column" fxFlex>
            <div fxLayout="row" fxLayoutAlign="start center">
                <h3 matSubheader class="push-none">Aviseringar</h3>

                <span fxFlex></span>

                <button mat-icon-button class="toggle-notifications-panel" [matMenuTriggerFor]="notificationMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>

            <mat-divider></mat-divider>

            <mat-nav-list class="notifications-list" fxFlex
                (click)="checkAndCloseNotificationSidenav(notificationSidenav)">
                <ng-container *ngIf="(notificationService.notifications | async) as notifications">
                    <ng-template ngFor let-notis [ngForOf]="notifications" let-i="index" let-last="last">

                        <a mat-list-item class="notification" [class.unread]="notis.unread"
                            (click)="notificationService.markSeenAndNavigate(notis, i)">
                            <div matListAvatar>
                                <mat-icon matListAvatar color="primary">{{notis.icon || 'info'}}</mat-icon>
                            </div>

                            <span matLine class="notification--message">{{notis.message | truncate:80}}</span>

                            <p matLine class="notification--timestamp">{{notis.createdAt | date:'short'}}</p>
                        </a>

                    </ng-template>

                    <mat-list-item *ngIf="!notifications.length">
                        <span>Inga notifikationer</span>
                    </mat-list-item>
                </ng-container>
            </mat-nav-list>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #notificationMenu="matMenu">
    <button mat-menu-item (click)="notificationService.markAllSeen()">Markera alla som läst</button>
</mat-menu>
