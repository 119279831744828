<div class="search-field-wrapper" fxLayout="column" ngClass.xs="mobile">
  <div class="search-field mat-elevation-z1">
    <input matInput type="search" [(ngModel)]="value" placeholder="Sök..." [disabled]="disabled" fxFlex>
    <button mat-icon-button (click)="onClear()" *ngIf="value">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-icon-button type="button" (click)="expander.toggle()" [disabled]="disabled" *ngIf="!noExpand">
      <mat-icon>filter_list</mat-icon>
    </button>
  </div>
  <app-expander #expander>
    <ng-content></ng-content>
  </app-expander>
</div>
