import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { AuthService } from '@app/auth/services/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-feedback-trigger',
  templateUrl: './feedback-trigger.component.html',
  styleUrls: ['./feedback-trigger.component.scss']
})
export class FeedbackTriggerComponent implements OnInit {

  protected popup: MatDialogRef<FeedbackDialogComponent>;

  constructor(
    private dialog: MatDialog,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

  openFeedbackDialog() {
    if (this.popup) {
      return this.popup;
    }

    this.popup = this.dialog.open(FeedbackDialogComponent, {
      width: '448px',
      panelClass: 'no-padding-dialog',
      closeOnNavigation: false,
      hasBackdrop: false,
      restoreFocus: true,
      autoFocus: true,
      disableClose: true,
      position: {
        right: '32px',
        top: '92px'
      }
    });

    this.popup.afterClosed().pipe(take(1)).subscribe(() => {
      this.popup = undefined;
    });

    return this.popup;
  }

}
