import { INavigationItem } from '@app/module-list/module-list.component';

export const ORGANISATION_ITEMS: INavigationItem[] = [
    {
        title: 'Överblick',
        icon: 'home',
        link: 'dashboard',
    },
    {
        link: 'inbox',
        requiredModule: 'Inbox',
        icon: 'move_to_inbox',
        title: 'Inbox'
    },
    {
        link: 'time-management',
        requiredModule: 'Tidhantering',
        icon: 'schedule',
        title: 'Tidhantering'
    },
    {
        link: 'errands',
        requiredModule: 'Ärenden',
        icon: 'assignment',
        title: 'Ärendehantering'
    },
    {
        link: 'project',
        requiredModule: 'Projekt',
        icon: 'work',
        title: 'Projekt'
    },
    {
        link: 'schema',
        requiredModule: 'Schema',
        icon: 'event',
        title: 'Schema'
    },
    {
        link: 'calendar',
        requiredModule: 'Kalender',
        icon: 'event',
        title: 'Calendar'
    },
    {
        link: 'resources',
        requiredModule: 'Resurshantering',
        icon: 'recent_actors',
        title: 'Resurshantering'
    },
    {
        link: 'timestamp',
        requiredModule: 'Stämpelklocka',
        icon: 'timer',
        title: 'Stämpelklocka'
    },
    {
        link: 'registry',
        requiredModule: 'Register',
        icon: 'books',
        title: 'Register'
    },
    {
        link: 'stats',
        requiredModule: 'Social',
        icon: 'insert_chart',
        title: 'Statistik'
    },
    {
        link: 'reports',
        requiredModule: 'Rapporter',
        icon: 'assignment_returned',
        title: 'Rapporter'
    },
    {
        link: 'assets',
        requiredModule: 'Assets',
        icon: 'cloud',
        title: 'Filer'
    },
    {
        link: 'settings',
        requiredModule: 'Inställningar',
        icon: 'settings',
        title: 'Inställningar'
    },
];

export const SALJ_ITEMS: INavigationItem[] = [
    {
        link: 'leads',
        requiredModule: 'Leads',
        icon: 'dns',
        title: 'Leads',
    },
    {
        link: 'live-support',
        requiredModule: 'Live Support',
        icon: 'person',
        title: 'Livechat',
    },
    {
        link: 'sales',
        requiredModule: 'Säljhantering',
        icon: 'dns',
        title: 'Säljhantering',
    },
    {
        link: 'assets',
        requiredModule: 'Assets',
        icon: 'cloud',
        title: 'Filer'
    },
    {
        link: 'settings',
        requiredModule: 'Inställningar',
        icon: 'settings',
        title: 'Inställningar'
    },
];

export const DIGITAL_MEDIA_ITEMS: INavigationItem[] = [
    {
        link: 'digitalsignage',
        requiredModule: 'Digital Signage',
        icon: 'screen_share',
        title: 'Digital Signage'
    },
    {
        link: 'assets',
        requiredModule: 'Assets',
        icon: 'cloud',
        title: 'Filer'
    },
    {
        link: 'settings',
        requiredModule: 'Inställningar',
        icon: 'settings',
        title: 'Inställningar'
    },
];


export const CMS_ITEMS: INavigationItem[] = [
    // {
    //     link: 'cms/',
    //     requiredModule: 'CMS',
    //     icon: 'public',
    //     title: 'Hemsida'
    // },
    {
        link: 'cms/facilities',
        requiredModule: 'CMS Anläggningar',
        icon: 'storefront',
        title: 'Anläggningar'
    },
    {
        link: 'cms/services',
        requiredModule: 'CMS Tjänster',
        icon: 'widgets',
        title: 'Tjänster'
    },
    {
        link: 'cms/campaigns',
        requiredModule: 'CMS Kampanjer',
        icon: 'whatshot',
        title: 'Kampanjer'
    },
    {
        link: 'cms/news',
        requiredModule: 'CMS Nyheter',
        icon: 'new_releases',
        title: 'Nyheter'
    },
    {
        link: 'cms/forms',
        requiredModule: 'CMS Formulär',
        icon: 'dvr',
        title: 'Formulär'
    },
    {
        link: 'cms/sliders',
        requiredModule: 'CMS Sliders',
        icon: 'view_carousel',
        title: 'Sliders'
    },
    {
        link: 'assets',
        requiredModule: 'Assets',
        icon: 'cloud',
        title: 'Filer'
    },
    {
        link: 'settings',
        requiredModule: 'Inställningar',
        icon: 'settings',
        title: 'Inställningar'
    },
];

export const ADMIN_ITEMS: INavigationItem[] = [
    {
        link: 'admin/company',
        requiredModule: 'Admin Företag',
        icon: 'supervisor_account',
        title: 'Företag'
    },
    {
        link: 'admin/users',
        requiredModule: 'Admin Användare',
        icon: 'assignment_ind',
        title: 'Användare'
    },
    {
        link: 'admin/news',
        requiredModule: 'Admin Nyheter',
        icon: 'new_releases',
        title: 'Nyheter'
    },
    {
        link: 'admin/modules',
        requiredModule: 'Admin Moduler',
        icon: 'view_module',
        title: 'Moduler'
    },
    {
        link: 'admin/tutorials',
        requiredModule: 'Admin Tutorialer',
        icon: 'help',
        title: 'Tutorialer'
    },
    {
        link: 'assets',
        requiredModule: 'Assets',
        icon: 'cloud',
        title: 'Filer'
    },
    {
        link: 'settings',
        requiredModule: 'Inställningar',
        icon: 'settings',
        title: 'Inställningar'
    },
];
