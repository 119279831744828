import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MomentModule } from 'angular2-moment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const ANGULAR_MODULES = [
  FormsModule,
  ReactiveFormsModule
];

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, MatCommonModule, MatLineModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

const MATERIAL_MODULES = [
  MatCardModule,
  MatButtonModule,
  MatTabsModule,
  MatListModule,
  MatSidenavModule,
  MatMenuModule,
  MatInputModule,
  MatToolbarModule,
  MatDialogModule,
  MatIconModule,
  MatStepperModule,
  MatSnackBarModule,
  MatRadioModule,
  MatSelectModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatChipsModule,
  MatTableModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatRippleModule,
  MatCommonModule,
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatBadgeModule,
  MatPaginatorModule,
  MatSortModule,
  MatDividerModule,
  MatLineModule,
  MatBottomSheetModule,
  NgxMatSelectSearchModule
];

import { CdkTableModule } from '@angular/cdk/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';

const CDK_MODULES = [
  CdkTableModule,
  ScrollingModule,
  A11yModule,
  DragDropModule
];

import {
  CovalentLayoutModule,
  CovalentLoadingModule,
  CovalentNotificationsModule,
  CovalentDialogsModule,
  CovalentSearchModule,
  CovalentCommonModule,
  CovalentPagingModule,
  CovalentDataTableModule,
  CovalentMediaModule,
  CovalentVirtualScrollModule,
  CovalentMenuModule,
  CovalentFileModule,
} from '@covalent/core';

const COVALENT_MODULES = [
  CovalentLayoutModule,
  CovalentLoadingModule,
  CovalentNotificationsModule,
  CovalentDialogsModule,
  CovalentSearchModule,
  CovalentCommonModule,
  CovalentPagingModule,
  CovalentDataTableModule,
  CovalentMediaModule,
  CovalentVirtualScrollModule,
  CovalentMenuModule,
  CovalentFileModule
];

import { HeaderComponent } from './header/header.component';
import { WrapperComponent } from './wrapper/wrapper.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DetailRowDirective } from '../directives/detail-row.directive';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { AuthRoleDirective } from '../auth/directives/auth-role.directive';
import { AuthModuleDirective } from '../auth/directives/auth-module.directive';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { InfoRowComponent } from './components/info-row/info-row.component';
import { DashboardCardDirective } from './directives/dashboard-card.directive';
import { DashboardCardContentComponent } from './components/dashboard-card-content/dashboard-card-content.component';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { InfoRowButtonComponent } from './components/info-row-button/info-row-button.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';

export function getSwedishPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Objekt per sida';

  paginatorIntl.getRangeLabel = (page: number, pageSize: number, totalResults: number) => {
    if (!totalResults) {
      return 'Inga resultat';
    }

    totalResults = Math.max(totalResults, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < totalResults ? Math.min(startIndex + pageSize, totalResults) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} av ${totalResults}`;
  };

  return paginatorIntl;
}

@NgModule({
  imports: [
    CommonModule,
    MATERIAL_MODULES,
    COVALENT_MODULES,
    ANGULAR_MODULES,
    CDK_MODULES,
    EcoFabSpeedDialModule,
    MomentModule,
    FlexLayoutModule,
    RouterModule,
    QuillModule.forRoot({
      theme: 'snow',
      format: 'html',
      placeholder: 'Innehåll här...',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          ['link', 'image'],
          ['clean']
        ]
      }
    })
  ],
  declarations: [
    HeaderComponent,
    WrapperComponent,
    DetailRowDirective,
    DashboardCardComponent,
    AuthRoleDirective,
    AuthModuleDirective,
    NoSanitizePipe,
    InfoRowComponent,
    DashboardCardDirective,
    DashboardCardContentComponent,
    InfoRowButtonComponent,
    ToolbarComponent
  ],
  exports: [
    MATERIAL_MODULES,
    COVALENT_MODULES,
    ANGULAR_MODULES,
    CDK_MODULES,
    EcoFabSpeedDialModule,
    MomentModule,
    HeaderComponent,
    WrapperComponent,
    FlexLayoutModule,
    DetailRowDirective,
    DashboardCardComponent,
    AuthRoleDirective,
    AuthModuleDirective,
    NoSanitizePipe,
    InfoRowComponent,
    DashboardCardDirective,
    DashboardCardContentComponent,
    InfoRowButtonComponent,
    ToolbarComponent,
    QuillModule
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useValue: getSwedishPaginatorIntl()
    }
  ]
})
export class UiModule { }
