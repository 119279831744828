import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TdMediaService } from '@covalent/core';
import { take } from 'rxjs/operators';
import { ORGANISATION_ITEMS, SALJ_ITEMS, DIGITAL_MEDIA_ITEMS, ADMIN_ITEMS, CMS_ITEMS } from '@app/data/navigationGroups';

export interface INavigationItem {
  title: string;
  link: string;
  icon: string;
  requiredModule?: string;
}

export interface INavigationGroup {
  items: INavigationItem[];
}

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModuleListComponent implements OnInit {
  @Input() type: number;
  @Input() nav: MatSidenav;

  navigationGroups: any[] = [
    ORGANISATION_ITEMS,
    SALJ_ITEMS,
    DIGITAL_MEDIA_ITEMS,
    ADMIN_ITEMS,
    CMS_ITEMS,
  ];

  constructor(private media: TdMediaService) { }

  ngOnInit() {
    if (!this.nav) {
      throw new Error('No [nav] typeof MatSidenav passed');
    }

    if (!this.type) {
      this.type = 1;
    }
  }

  closeMenu() {
    this.media.registerQuery('gt-md').pipe(take(1)).subscribe((enabled: boolean) => {
      if (!enabled) {
        this.nav.close();
      } else {
        return;
      }
    });
  }

}
