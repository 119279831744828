import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { APIResponse } from '@app/interfaces/apiResponse';
import { Subject } from 'rxjs';
import { Cacheable, CacheBuster } from 'ngx-cacheable';

const tutorialsCache$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  readonly domain = `${environment.domain}tutorials`;

  constructor(
    private http: HttpClient
  ) { }

  @Cacheable({
    cacheBusterObserver: tutorialsCache$,
    maxAge: 3600000
  })
  getTutorials(_options?: {
    search?: string,
    limit?: string
  }) {
    const params = new HttpParams({
      fromObject: _options
    });

    return this.http.get<APIResponse>(this.domain, {
      params: params
    });
  }

  @Cacheable({
    cacheBusterObserver: tutorialsCache$,
    maxAge: 3600000
  })
  getTutorial(tutorialId: string) {
    return this.http.get<APIResponse>(`${this.domain}/${tutorialId}`);
  }

  @CacheBuster({
    cacheBusterNotifier: tutorialsCache$
  })
  createTutorial(tutorial: any) {
    return this.http.post<APIResponse>(this.domain, tutorial);
  }

  @CacheBuster({
    cacheBusterNotifier: tutorialsCache$
  })
  updateTutorial(tutorialId: string, tutorial: any) {
    return this.http.put<APIResponse>(`${this.domain}/${tutorialId}`, tutorial);
  }

  @CacheBuster({
    cacheBusterNotifier: tutorialsCache$
  })
  deleteTutorial(tutorialId: string) {
    return this.http.delete<APIResponse>(`${this.domain}/${tutorialId}`);
  }
}
