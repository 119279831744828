import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@env/environment';
import * as io from 'socket.io-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  socket: SocketIOClient.Socket | undefined;
  notifications: BehaviorSubject<any[]> = new BehaviorSubject([]);

  sidenav?: MatSidenav;

  sub$ = new Subject();

  public get connected() {
    return this.socket ? this.socket.connected : false;
  }

  public get unread$() {
    return this.notifications.pipe(map(notis => notis.filter(n => n.unread === true)));
  }

  public get unreadLength$() {
    return this.unread$.pipe(map(unread => unread.length));
  }

  constructor(
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private router: Router,
    public media: TdMediaService,
  ) { }

  public init() {
    if (!this.socket) {
      const token = this.auth.getRawToken();
      if (token) {
        this.connect(token);
      } else {
        this.disconnect();
      }
    }

    this.auth.loggedIn.pipe(takeUntil(this.sub$)).subscribe(_loggedIn => {
      if (!_loggedIn) {
        this.notifications.next([]);
      }
    });
  }

  private connect(userToken: any) {
    this.socket = io.connect(environment.websocketDomain, {
      path: environment.notificationSocketPath,
      forceNew: true
    });

    this.socket.on('connect', () => {
      this.socket.emit('authentication', { token: userToken });
    });

    this.socket.on('notification', (data: any) => {
      if (Array.isArray(data)) {
        this.notifications.next(data);
      } else {
        const newArr = this.notifications.getValue();
        newArr.unshift(data);
        this.notifications.next(newArr);
      }
    });

    this.socket.on('unauthorized', (err) => {
      console.error('SOCKETIO AUTH ERROR:', err);
    });

  }

  public markSeenAndNavigate(notification: any, arrayId: number) {
    if (notification.link) {
      this.router.navigate([notification.link]);
    }

    if (!notification.unread) {
      return;
    }

    const data = {
      id: notification._id
    };

    this.socket.emit('markSeen', data, fn => {
      if (fn) {
        const arr: any[] = this.notifications.getValue();
        arr.splice(arrayId, 1, fn);
        this.notifications.next(arr);
      }
    });
  }

  public markAllSeen() {
    this.socket.emit('markAllSeen', fn => {
      if (fn) {
        this.socket.emit('getNotifications');
      }
    });
  }

  public toggleSidenav() {
    if (this.sidenav) {
      this.sidenav.toggle();
    }
  }

  public disconnect() {
    if (!this.socket) {
      return;
    }

    this.socket.disconnect();
    this.socket = undefined;
  }

  ngOnDestroy() {
    this.sub$.next();
    this.sub$.complete();
  }

}
