import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIResponse } from '@app/interfaces/apiResponse';
import { take, publishLast, refCount, shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { IModule } from '@app/interfaces/module';
import { handleApiResponse } from '@app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesService implements OnDestroy {
  readonly userDomain = environment.domain + 'modules/';
  readonly domain = environment.domain + 'management/modules/';

  private _modules: any[] | null;
  public modules: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private http: HttpClient) {
    this._modules = JSON.parse(localStorage.getItem('modules'));

    this.modules.next(this._modules);
  }

  isActive(moduleOrCategoryName: string) {
    if (!this._modules) {
      return false;
    }
    const module = this.findModule(moduleOrCategoryName);
    if (!module) {
      return false;
    } else {
      return module.active;
    }
  }

  /**
   * Finds a module
   * @param moduleOrCategoryName Search by module or category name
   */
  public findModule(moduleOrCategoryName: string) {
    return this._modules.find((m) => {
      // Check if module has a category
      // and if the module can be found with the category name
      if (m.category && m.category.toLowerCase() === moduleOrCategoryName.toLowerCase()) {
        return true;
      }

      // If no modules were found with category name
      // check for module name
      if (m.name.toLowerCase() === moduleOrCategoryName.toLowerCase()) {
        return true;
      }

      // Return false if none found
      return false;
    });
  }

  /**
   * Fetches all modules from the server
   * @param onlyActive Only get active modules?
   */
  getModules(onlyActive?: boolean): Observable<APIResponse> {
    if (onlyActive) {
      return this.http.get<APIResponse>(this.domain, { params: { onlyActive: String(onlyActive) } }).pipe(publishLast(), refCount());
    } else {
      return this.http.get<APIResponse>(this.domain).pipe(publishLast(), refCount());
    }
  }

  fetchUserModules() {
    return this.http
      .get<APIResponse>(this.userDomain)
      .pipe(
        handleApiResponse<any[]>('modules')
      );
  }

  getUserModules(): IModule[] {
    // const modules: any[] = this.modules || JSON.parse(localStorage.getItem('modules'));
    return this._modules;
  }

  public updateUserModules() {
    this.fetchUserModules()
      .pipe(
        take(1)
      )
      .subscribe((fetchedModules) => {
        this._modules = fetchedModules.map(m => ({
          name: m.name,
          active: m.active,
          category: m.category
        }));

        localStorage.setItem('modules', JSON.stringify(this._modules));

        this.modules.next(this._modules);
      }, (err) => {
        console.error(err);

        this.modules.next([]);

        throw err;
      });
  }

  public async updateModules() {
    try {
      console.log('Updating modules...');

      const fetchedModules = await this.fetchUserModules().toPromise();

      if (!fetchedModules) {
        return false;
      }

      this._modules = fetchedModules.map(m => ({
        name: m.name,
        active: m.active,
        category: m.category
      }));

      localStorage.setItem('modules', JSON.stringify(this._modules));

      this.modules.next(this._modules);

      return true;
    } catch (error) {
      this.modules.next([]);

      console.log(error);

      return false;
    }
  }

  createModule(data): Observable<APIResponse> {
    return this.http.post<APIResponse>(this.domain, data).pipe(publishLast(), refCount());
  }

  updateModule(id, data): Observable<APIResponse> {
    return this.http.put<APIResponse>(this.domain + id, data).pipe(publishLast(), refCount());
  }

  deleteModule(id): Observable<APIResponse> {
    return this.http.delete<APIResponse>(this.domain + id).pipe(publishLast(), refCount());
  }

  ngOnDestroy() {
    this.modules.next([]);
    this.modules.complete();
  }

}
