import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {

  constructor(public notificationService: NotificationService) { }

  ngOnInit() {
  }

  markSeen(notis: any, index: number) {
    this.notificationService.markSeenAndNavigate(notis, index);
  }

  markAllSeen() {
    this.notificationService.markAllSeen();
  }

}
