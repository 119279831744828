import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as io from 'socket.io-client';
import * as ss from 'socket.io-stream/socket.io-stream';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/auth/services/auth.service';
import { environment } from '@env/environment';
import { map, shareReplay } from 'rxjs/operators';
import { UsersService } from '@app/users/users.service';
import { handleApiResponse } from '@app/services/api.service';
import { IUser, ICompanyUser } from '@app/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  socket?: SocketIOClient.Socket;

  fileUploadProgress: Observable<any>;

  private username = '';

  public onlineStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.getCachedOnlineStatus());

  visitorCount$: Observable<number>;

  constructor(
    private snackbar: MatSnackBar,
    private usersService: UsersService
  ) {
    const cachedOnlineStatus = this.getCachedOnlineStatus();

    this.setOnlineStatus(cachedOnlineStatus);
  }

  setOnlineStatus(status: boolean) {
    return status ? this.connectToChat() : this.disconnect();
  }

  async connectToChat() {
    const user = await this.usersService
      .getProfile()
      .pipe(
        handleApiResponse<ICompanyUser>()
      )
      .toPromise();

    if (!user) {
      throw new Error('Not logged in!');
    }

    this.socket = io(environment.chatDomain + user.company.name, {
      query: {
        username: user.username,
        type: 'Operator',
        icon: user.photo ? user.photo.url : undefined,
        secret: '486a9c39-6baa-45c6-a2c1-11c11bf70559'
      },
      path: environment.chatSocketPath
    });

    this.username = user.username;

    localStorage.setItem('online', 'true');

    this.onlineStatus$.next(true);

    setTimeout(() => {
      this.snackbar.open('Uppkopplad till live support', 'OK', {
        duration: 3000,
        verticalPosition: 'top'
      });
    }, 0);

    if (!this.socket) {
      localStorage.removeItem('online');

      this.onlineStatus$.next(false);

      setTimeout(() => {
        this.snackbar.open('Kunde inte koppla upp till chatten', '', {
          duration: 5000,
          horizontalPosition: 'center'
        });
      }, 0);
    }

    this.getMessagesStatus();
  }

  getMessagesStatus() {
    this.visitorCount$ = this.on('getVisitors').pipe(
      map(newVisitors => {
        const visitors = Object.values(newVisitors);
        return visitors.length;
      })
    );
  }

  private getCachedOnlineStatus() {
    return !!localStorage.getItem('online');
  }

  public sendMessage(msg: string, room: string) {
    this.socket.emit('message', {
      msg: msg,
      sender: this.username,
      room: room
    });
  }

  public sendFile(room: string, file: File) {
    this.fileUploadProgress = new Observable((observer) => {
      const stream = ss.createStream();
      ss(this.socket).emit('file-upload', stream, {
        sender: this.username,
        room: room,
        name: file.name,
        type: file.type,
        size: file.size
      });
      const bStream = ss.createBlobReadStream(file);
      let size = 0;
      bStream.on('data', (chunk: any) => {
        size += chunk.length;
        const percent = Math.floor(size / file.size * 100);
        observer.next(percent);
      });
      bStream.pipe(stream);
      bStream.on('end', () => {
        observer.next(true);
      });
    });
  }

  public joinRoom(roomName: string) {
    this.socket.emit('joinRoom', roomName);
    this.socket.emit('userMessagesRead', roomName);
    this.getHistory();
  }

  public leaveRoom(roomName: string) {
    this.socket.emit('leaveRoom', roomName);
  }

  public sendTypingStatus(status: boolean) {
    const data = {
      name: this.username,
      status: status
    };
    this.socket.emit('typing', data);
  }

  public getHistory() {
    this.socket.emit('getHistory');
  }

  public getOperators() {
    this.socket.emit('getOperators');
  }

  public on<T = any>(method: string) {
    return new Observable<T>(observer => {
      this.socket.on(method, (data: any) => {
        observer.next(data);
      });
    }).pipe(
      shareReplay(1)
    );
  }

  public disconnect() {
    if (!this.socket) {
      return true;
    }

    this.socket.disconnect();

    localStorage.removeItem('online');

    this.onlineStatus$.next(false);

    this.snackbar.open('Bortkopplad från live support', '', {
      duration: 3000,
      horizontalPosition: 'center'
    });
  }
}
