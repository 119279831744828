<app-wrapper headerTitle="Notifikationer" headerBody="Här kan du se alla dina notifikationer">
  <button mat-flat-button color="primary" (click)="markAllSeen()">Markera alla som lästa</button>
  <mat-nav-list disableRipple>
    <ng-template let-notis ngFor [ngForOf]="(notificationService.notifications | async)" let-i="index">
      <mat-list-item class="notification" [class.unread]="notis.unread">
        <div matListAvatar>
          <mat-icon matListAvatar>{{notis.icon || 'info'}}</mat-icon>
        </div>
        <span matLine>{{notis.message}}</span>
        <p matLine>{{notis.createdAt | date:'medium'}}</p>
        <ng-container *ngIf="notis.link">
          <a mat-button color="primary" (click)="markSeen(notis, i)">
            <mat-icon>chevron_right</mat-icon>
          </a>
        </ng-container>
      </mat-list-item>
    </ng-template>
  </mat-nav-list>
</app-wrapper>
