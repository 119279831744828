import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ModulesService } from './modules.service';
import { environment } from '@env/environment';
import { publishLast, refCount } from 'rxjs/operators';
import { IUser } from '@app/interfaces/user';
import { APIResponse } from '@app/interfaces/apiResponse';
import { globalCacheBusterNotifier } from 'ngx-cacheable';
import { handleApiResponse } from '@app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  readonly domain = environment.domain;
  Superuser = 'Superuser';

  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService,
    private moduleService: ModulesService
  ) {
    this.loggedIn.next(this.userTokenValid(false));
  }

  login(data: any): Observable<APIResponse> {
    return this.http.post<APIResponse>(this.domain + 'auth/login', data);
  }

  forgotPassword(email: string): Observable<APIResponse> {
    return this.http.post(this.domain + 'auth/forgot', { email: email }).pipe(publishLast(), refCount());
  }

  resetPassword(token: string, newPassword: string): Observable<APIResponse> {
    return this.http.post(this.domain + 'auth/reset', { token: token, password: newPassword }).pipe(publishLast(), refCount());
  }

  getRawToken() {
    return localStorage.getItem('token');
  }

  getTokenData() {
    const data = this.jwtHelper.decodeToken();
    return data ? data : {};
  }

  /**
   * Gets the current logged in user data
   *
   * @returns user data
   * @memberof AuthService
   */
  getUser() {
    const user = this.getTokenData().user;
    if (user) {
      return user as IUser;
    } else {
      return null;
    }
  }

  private userTokenValid(redirectOnInvalid = true) {
    const token: string = localStorage.getItem('token');

    if (!token || this.jwtHelper.isTokenExpired()) {
      if (redirectOnInvalid) {
        this.logout();
      }
      return false;
    }

    return token != null && !this.jwtHelper.isTokenExpired(token);
  }

  async saveUserDataAndUpdateState(token: string) {
    await this.storeUserData(token);
    await this.moduleService.updateModules();
    this.loggedIn.next(true);
    return this.loggedIn.getValue();
  }

  storeUserData(token: string) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        localStorage.setItem('token', token);

        resolve(true);
      }, 0);
    });
  }

  public isOwner(userIdToMatch: string) {
    if (!userIdToMatch) {
      return false;
    }

    const user = this.getUser();

    return String(user.userId) === String(userIdToMatch);
  }

  public hasRole(role: string) {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    if (!user.role) {
      this.logout();
      return false;
    }

    if (user && user.role) {
      return (user.role.toLowerCase() === role.toLowerCase() || user.role.toLowerCase() === this.Superuser.toLowerCase());
    } else {
      return false;
    }
  }

  /**
   * Returns if the user has access to a module
   *
   * @param {string} moduleOrCategoryName the module or category to test access for
   * @returns a boolean value if the user has access or not
   * @memberof AuthService
   */
  public hasAccess(moduleOrCategoryName: string) {
    if (!moduleOrCategoryName) {
      return false;
    }

    const userModules = this.moduleService.getUserModules();

    if (!userModules) {
      return false;
    }

    if (this.moduleService.isActive(moduleOrCategoryName)) {
      if (this.hasRole('Superuser')) {
        return true;
      } else {
        return !!this.moduleService.findModule(moduleOrCategoryName);
      }
    } else {
      return false;
    }
  }

  setupNewUser(userData: any) {
    return this.http.post<APIResponse>(this.domain + 'auth/register', userData);
  }

  registerCompany(data): Observable<APIResponse> {
    return this.http.post(this.domain + 'auth/registercompany', data).pipe(publishLast(), refCount());
  }

  logout() {
    // Update status
    this.loggedIn.next(false);

    // Clear storage
    localStorage.clear();

    // Clear cache
    globalCacheBusterNotifier.next();

    setTimeout(() => {
      // Navigate to login
      this.router.navigate(['/auth/login']);
    }, 0);
  }

  ngOnDestroy() {
    this.loggedIn.next(false);
    this.loggedIn.complete();
  }
}
