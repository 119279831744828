import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { APIResponse } from '@app/interfaces/apiResponse';
import { take, map, catchError, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  private createHttpParams(params = {}): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    Object.keys(params).forEach((param) => {
      if (params[param]) {
        httpParams = httpParams.set(param, params[param]);
      }
    });

    return httpParams;
  }

  get(requestOptions: {
    url: string
    query?: any
  }) {
    return this.http.get<APIResponse>(`${requestOptions.url}`, {
      params: this.createHttpParams(requestOptions.query)
    });
  }

  getPaginated({
    url,
    page = 0,
    limit = 50,
    query,
  }: {
    url: string,
    page: number,
    limit: number,
    query?: any
  }) {
    return this.http.get<APIResponse>(`${url}`, {
      params: this.createHttpParams({
        ...query,
        page,
        limit,
      })
    });
  }

  post(requestOptions: {
    url: string
    query?: any,
    body: any
  }) {
    return this.http.post<APIResponse>(`${requestOptions.url}`, requestOptions.body, {
      params: this.createHttpParams(requestOptions.query)
    });
  }

  put(requestOptions: {
    url: string
    query?: any,
    body: any
  }) {
    return this.http.put<APIResponse>(`${requestOptions.url}`, requestOptions.body, {
      params: this.createHttpParams(requestOptions.query)
    });
  }

  patch(requestOptions: {
    url: string
    query?: any,
    body: any
  }) {
    return this.http.patch<APIResponse>(`${requestOptions.url}`, requestOptions.body, {
      params: this.createHttpParams(requestOptions.query)
    });
  }

  delete(requestOptions: {
    url: string
    query?: any
  }) {
    return this.http.delete<APIResponse>(`${requestOptions.url}`, {
      params: this.createHttpParams(requestOptions.query)
    });
  }
}

export function handleApiResponse<T = any>(dataProperty?: string) {
  return (sourceObservable$: Observable<APIResponse>) => sourceObservable$.pipe(
    take(1),
    map((data) => {
      if (!data.success) {
        throw data.message;
      }

      const mappedData: T = dataProperty ? data.data[dataProperty] : data.data;

      return mappedData;
    }),
    // catchError((err) => {
    //   console.error(err);

    //   return of<T>(null);
    // }),
    share(),
  );
}
