<h1 mat-dialog-title>Välj ärende</h1>
<form [formGroup]="searchForm">
  <app-search-field [noExpand]="true" formControlName="search"></app-search-field>
  <ng-container *ngIf="loading$ | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
</form>
<mat-dialog-content style="overflow: hidden auto">
  <mat-nav-list>
    <mat-list-item *ngFor="let errand of (errands$ | async)" (click)="selectedErrand = errand">
      <h3 matLine>{{errand.title || 'Ärendet har ingen titel'}}</h3>
      <p matLine>{{(errand.customer?.name || 'Kunden hittades inte') | truncate:40}}</p>
      <mat-icon *ngIf="selectedErrand?._id === errand?._id" color="primary">check_outline</mat-icon>
    </mat-list-item>
  </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions align="end" fxLayoutGap="16px">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="selectedErrand" [disabled]="!selectedErrand">Välj
    ärende</button>
</mat-dialog-actions>
