import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { FeedbackStartComponent } from './feedback-start/feedback-start.component';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';
import { ArticleComponent } from './article/article.component';

const routes: Routes = [
    {
        path: '',
        component: FeedbackStartComponent,
        outlet: 'help'
    },
    {
        path: 'tutorial/:tutorialId',
        component: ArticleComponent,
        outlet: 'help'
    },
    {
        path: 'feedback',
        component: SendFeedbackComponent,
        outlet: 'help'
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FeedbackRoutingModule { }
