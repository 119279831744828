<mat-toolbar cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="html" color="primary"
  class="mat-elevation-z4 feedback-toolbar">
  <mat-toolbar-row cdkDragHandle>
    <button mat-icon-button [routerLink]="['/', {outlets: {help: null}}]" *ngIf="back">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span>{{title}}</span>
    <span fxFlex></span>
    <button mat-icon-button mat-dialog-close type="button">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="content">
    <ng-content></ng-content>
  </mat-toolbar-row>
</mat-toolbar>
