<mat-toolbar color="primary" class="mat-elevation-z12 app-toolbar" *ngIf="(auth.loggedIn | async)" ngClass.xs="mobile">
  <ng-container *ngIf="toolbar.canGoBack; else menuTemplate">
    <button mat-icon-button (click)="toolbar.goBack()" class="animated fadeIn faster">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </ng-container>

  <span class="toolbar-title no-text-overflow animated fadeIn faster" fxFlex>{{toolbar.pageTitle$ | async}}</span>

  <ng-content></ng-content>

  <button mat-icon-button [matMenuTriggerFor]="companyMenu" *appAuthModule="'Prorec,CMS'">
    <mat-icon>storefront</mat-icon>
  </button>

  <mat-menu #companyMenu="matMenu" xPosition="before">
    <ng-template matMenuContent>
      <span matSubheader>CMS Företag</span>

      <ng-container *ngIf="(companies$ | async) as companies; else loadingCompanies">
        <ng-template ngFor let-company [ngForOf]="companies">
          <button mat-menu-item (click)="setCMSCompany(company._id)" [class.active]="selectedCompanyId === company._id">
            <mat-icon>{{selectedCompanyId === company._id ? 'radio_button_checked' : 'radio_button_unchecked'}}
            </mat-icon>

            <span>{{company.name}}</span>
          </button>
        </ng-template>
      </ng-container>

      <ng-template #loadingCompanies>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-template>
    </ng-template>
  </mat-menu>

  <mat-slide-toggle *appAuthModule="'Live Support'" [checked]="chatOnline$ | async"
    (change)="chatService.setOnlineStatus($event.checked)" class="livesupport-toggle animated fadeIn faster"
    [matBadge]="visitorCount$ | async" [matBadgeOverlap]="true" [matBadgeHidden]="!(chatOnline$ | async)">
  </mat-slide-toggle>

  <button mat-icon-button (click)="notificationService.toggleSidenav()" class="animated fadeIn faster">
    <mat-icon [matBadge]="notificationService.unreadLength$ | async"
      [matBadgeHidden]="!(notificationService.unreadLength$ | async)" matBadgeColor="accent">notifications</mat-icon>
  </button>
</mat-toolbar>

<ng-template #menuTemplate>
  <button mat-icon-button (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
</ng-template>
