<app-feedback-toolbar [content]="true">
  <app-search-field class="search-field" [formControl]="searchControl" [noExpand]="true"></app-search-field>
</app-feedback-toolbar>

<mat-dialog-content>
  <div fxLayout="column">
    <mat-nav-list>
      <div class="search-results">
        <ng-container *ngIf="(filteredResults$ | async) as results">
          <h3 matSubheader>Sökresultat</h3>
          <ng-container *ngIf="results.length; else noResultsTemplate">
            <ng-template ngFor let-result [ngForOf]="results" let-last="last">
              <mat-list-item [routerLink]="['/', {outlets: {help: ['tutorial', result._id]}}]">
                <mat-icon matListIcon color="accent">receipt</mat-icon>
                <span matLine>{{result.title}}</span>
              </mat-list-item>
              <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>

      <h3 matSubheader>Kontakta oss</h3>
      <mat-list-item [routerLink]="['/', {outlets: {help: ['feedback']}}]">
        <mat-icon matListIcon color="accent">feedback</mat-icon>
        <span matLine>Skicka feedback</span>
      </mat-list-item>
    </mat-nav-list>
  </div>
</mat-dialog-content>

<ng-template #noResultsTemplate>
  <mat-list-item>Inga resultat...</mat-list-item>
</ng-template>
