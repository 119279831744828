import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { NotificationService } from '@app/notifications/services/notification.service';
import { ChatService } from '@app/livesupport/services/chat.service';
import { Router } from '@angular/router';
import { ToolbarService } from '@app/services/toolbar.service';
import { Observable } from 'rxjs';
import { ICompany } from '@app/interfaces/company';
import { ManagementService } from '@app/admin/services/management.service';
import { handleApiResponse } from '@app/services/api.service';
import { CmsService } from '@app/cms/services/cms.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit {

  @Output()
  menu: EventEmitter<void> = new EventEmitter<void>();

  public get visitorCount$() {
    return this.chatService.visitorCount$;
  }

  public get chatOnline$() {
    return this.chatService.onlineStatus$;
  }

  companies$: Observable<ICompany[]>;

  public get selectedCompanyId(): string {
    return this.cmsService.companyId;
  }

  constructor(
    public auth: AuthService,
    public notificationService: NotificationService,
    public chatService: ChatService,
    public router: Router,
    public toolbar: ToolbarService,
    private managementService: ManagementService,
    private cmsService: CmsService
  ) { }

  ngOnInit() {
    this.companies$ = this.managementService.getCompanies().pipe(
      handleApiResponse()
    );
  }

  setCMSCompany(companyId: string) {
    this.cmsService.companyId = companyId;
  }

  toggleMenu() {
    this.menu.emit();
  }

}
