import { merge, fromEvent, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export function onlineStatus$() {
  return merge(
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false)),
    of(navigator.onLine),
  );
}
