import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first, takeUntil, tap } from 'rxjs/operators';
import { interval, concat, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateService implements OnDestroy {

  sub$ = new Subject<void>();

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate
  ) { }

  initCheck() {
    if (this.swUpdate.isEnabled) {
      console.log('Service worker available!');
    } else {
      console.log('Service worker not available!');

      return;
    }

    // Wait for app to stabilize
    const isStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true),
      tap(() => console.log('App is stable!'))
    );

    const checkEveryHours = 1;

    const updateCheckInterval$ = interval(checkEveryHours * 60 * 60 * 1000);

    const updateCheckWhenStable$ = concat(isStable$, updateCheckInterval$);

    updateCheckWhenStable$.pipe(
      takeUntil(this.sub$)
    ).subscribe(() => {
      this.swUpdate.checkForUpdate();
    });
  }

  ngOnDestroy() {
    this.sub$.next();

    this.sub$.complete();
  }

}
