<mat-sidenav-container fxFlexFill>
  <mat-sidenav #nav [mode]="(media.registerQuery('gt-md') | async) ? 'side' : 'over'" class="main-sidenav"
    [opened]="(media.registerQuery('gt-md') | async) && (auth.loggedIn | async) && !(printService.isPrinting$ | async)"
    ngClass.xs="mobile">
    <div fxLayout="column" fxFlex>
      <div class="nav-header" fxFlex="none" fxLayout="column">
        <img src="assets/prorec_vit.png" alt="Prorec Logo" class="prorec-logo" />

        <button mat-icon-button fxHide fxShow.xs (click)="nav.close()" class="close-main-sidenav-button">
          <mat-icon>close</mat-icon>
        </button>

        <mat-form-field appearance="none" color="primary" class="nav-select" *ngIf="(auth.loggedIn | async)">
          <mat-select [(ngModel)]="navType">
            <mat-option [value]="1" *appAuthModule="'Organisation'">Organisation</mat-option>

            <mat-option [value]="2" *appAuthModule="'Salj'">Sälj</mat-option>

            <mat-option [value]="3" *appAuthModule="'Digitala medier'">Digitala medier</mat-option>

            <mat-option [value]="5" *appAuthModule="'CMS'">Hemsida</mat-option>

            <mat-option [value]="4" *appAuthModule="'Administration'">Admin</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-module-list [type]="navType" [nav]="nav" *ngIf="(auth.loggedIn | async)">
      </app-module-list>

      <span fxFlex></span>

      <button class="exit-button" fxFlex="none" color="warn" mat-flat-button (click)="logout()">
        <span>Logga ut</span>
      </button>

      <div matSubheader fxLayout="column" fxLayoutAlign="start start" fxFlex="none">
        <span>Copyright © 2017 - {{year}} Prorec</span>

        <span>Version Beta</span>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="app-layout-wrapper">
      <div class="app-layout-nav-wrapper">
        <app-toolbar (menu)="nav.toggle()"> </app-toolbar>

        <div class="app-layout-content-wrapper" fxFlex [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
          <prorec-notifications-sidenav fxFlex>
            <router-outlet #o="outlet"></router-outlet>
          </prorec-notifications-sidenav>
        </div>
      </div>
    </div>

    <app-feedback-trigger *ngIf="(auth.loggedIn | async) && !(printService.isPrinting$ | async)"></app-feedback-trigger>
  </mat-sidenav-content>
</mat-sidenav-container>
