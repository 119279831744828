<app-feedback-toolbar [back]="true"></app-feedback-toolbar>

<form [formGroup]="feedbackForm" (ngSubmit)="sendFeedback(feedbackForm.value)">
  <div fxLayout="column" class="feedback-content">
    <mat-progress-bar mode="indeterminate" *ngIf="loading$ | async"></mat-progress-bar>
    <mat-form-field appearance="fill" fxFlex>
      <mat-label>Feedback...</mat-label>
      <textarea matInput formControlName="message" rows="6" required></textarea>
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit" [disabled]="!feedbackForm.valid">Skicka feedback</button>
  </div>
</form>
