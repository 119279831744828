import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { APIResponse } from '../interfaces/apiResponse';
import { Router } from '@angular/router';
import { IErrand } from './errand';
import { Location } from '@angular/common';
import { InboxEmail } from '@app/inbox/inbox';
import Utility from '@app/utility/utility';
import { IAsset } from '@app/asset/asset.interface';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ErrandsService {
  readonly domain = environment.domain + 'errands/';

  newErrandData: Partial<IErrand>;

  constructor(
    private api: ApiService,
    private router: Router,
    private location: Location
  ) { }

  newErrand(errandData: any) {
    return this.api.post({
      url: this.domain,
      body: errandData
    });
  }

  getUserErrands(options: {
    page?: number,
    limit?: number,
    status?: string,
    search?: string;
    startAt?: string;
    endAt?: string;
    isOrder?: string;
    customer?: string;
    project?: string;
  }) {
    return this.api.get({
      url: this.domain,
      query: options
    });
  }

  getActiveErrands() {
    return this.getUserErrands({
      status: 'I produktion,Granskas',
      limit: 10
    });
  }

  getAllErrands(): Observable<APIResponse> {
    return this.api.get({
      url: this.domain
    });
  }

  getErrand(id: string): Observable<APIResponse> {
    return this.api.get({
      url: `${this.domain}${id}`
    });
  }

  updateErrand(id: string, errandData: any) {
    return this.api.put({
      url: this.domain + id,
      body: errandData
    });
  }

  deleteErrand(id: string): Observable<APIResponse> {
    return this.api.delete({
      url: this.domain + id
    });
  }

  postMessage(id: string, msg: any) {
    return this.api.post({
      url: this.domain + 'messages/' + id,
      body: {
        message: msg
      }
    });
  }

  addAssetsToErrandById(errandId: string, assets: IAsset[]) {
    return this.api.post({
      url: `${this.domain}files/${errandId}`,
      body: {
        assets,
      }
    });
  }

  uploadFiles(errandId: string, filesForm: FormData) {
    return this.api.post({
      url: `${this.domain}files/${errandId}`,
      body: filesForm
    });
  }

  // downloadAllFiles(errandId: string) {
  //   return this.http.get(`${this.domain}files/${errandId}`, {
  //     responseType: 'arraybuffer'
  //   });
  // }

  // deleteFile(errandId: string, fileId: string) {
  //   return this.http.delete<APIResponse>(`${this.domain}files/${errandId}/${fileId}`);
  // }

  navigateNewErrand(data: Partial<IErrand>) {
    this.newErrandData = {
      ...data,
      emails: data.emails ? data.emails.map(email => {
        return {
          ...email,
          attachments: undefined
        };
      }) : []
    };
    this.router.navigate(['/errands/new'], {
      queryParams: {
        getData: true
      },
    }).then(() => {
      this.location.replaceState('/errands/new');
    });
  }

  addEmailToErrand(errandId: string, email: InboxEmail) {
    // Convert data to FormData so the body can contain files
    const formData = Utility.JSONifyForm({
      email: {
        ...email,
        attachment: undefined
      }
    });

    // Convert any attachments from the email to "File" and append to form
    email.attachments.map(attachment => {
      formData.append('file', Utility.generateFileFromMailAttachment(attachment));
    });

    return this.api.post({
      url: `${this.domain}emails/${errandId}`,
      body: formData
    });
  }

}
