import { AppRoutingModule } from './app.routing';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { Error404Component } from './error404/error404.component';
import { environment } from '../environments/environment';
import { UiModule } from '@app/ui/ui.module';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { ModuleListComponent } from './module-list/module-list.component';
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { InvalidLoginInterceptor } from './global/invalid-login-interceptor';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FeedbackModule } from './feedback/feedback.module';
import { BodyInterceptor } from './global/body-interceptor';
import { NotificationsModule } from './notifications/notifications.module';
import { SharedModule } from './shared/shared.module';

const facebookLoginOptions: LoginOpt = {
  auth_type: 'rerequest',
  scope: 'instagram_basic,instagram_manage_insights,pages_show_list,manage_pages,leads_retrieval,read_insights',
  return_scopes: true,
  fetch_basic_profile: true,
  enable_profile_selector: true,
  prompt: 'select_account'
};

const socialConfig = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookAppId, facebookLoginOptions)
  }
]);

export function provideSocialConfig() {
  return socialConfig;
}

export function tokenGetter() {
  return localStorage.getItem('token');
}

registerLocaleData(localeSv, 'sv');

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    ModuleListComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.whitelistedDomains
      }
    }),
    BrowserAnimationsModule,
    UiModule,
    SharedModule,
    MatMomentDateModule,
    SocialLoginModule,
    FeedbackModule,
    NotificationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'sv-SE'
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideSocialConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InvalidLoginInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BodyInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'sv-Se'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
