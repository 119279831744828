import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Http, RequestOptions, Headers } from '@angular/http';
import { Observable, Subject } from 'rxjs';
// import { tokenNotExpired } from 'angular2-jwt';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import Utility from '@app/utility/utility';
import { APIResponse } from '@app/interfaces/apiResponse';
import { ICompany } from '@app/interfaces/company';
import { Cacheable, CacheBuster } from 'ngx-cacheable';

const companyCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  readonly companyDomain = environment.domain + 'management/company/';
  readonly userDomain = environment.domain + 'management/user/';

  constructor(private http: HttpClient, private router: Router) { }

  /* GET */

  @Cacheable({
    cacheBusterObserver: companyCacheBuster$
  })
  getCompanies(): Observable<APIResponse> {
    return this.http.get<APIResponse>(this.companyDomain).pipe(catchError(Utility.handleError));
  }

  @Cacheable({
    cacheBusterObserver: companyCacheBuster$
  })
  getCompany(id): Observable<APIResponse> {
    return this.http.get<APIResponse>(this.companyDomain + id).pipe(catchError(Utility.handleError));
  }

  getUsers(id): Observable<APIResponse> {
    return this.http.get<APIResponse>(this.companyDomain + id + '/users').pipe(catchError(Utility.handleError));
  }

  getAllUsers(page?: number, limit?: number) {
    return this.http.get<APIResponse>(this.userDomain + `?page=${page}&limit=${limit}`).pipe(catchError(Utility.handleError));
  }

  getUser(userId: string, query?: any): Observable<APIResponse> {
    return this.http.get<APIResponse>(this.userDomain + userId, { params: query }).pipe(catchError(Utility.handleError));
  }

  /* CREATE */

  @CacheBuster({
    cacheBusterNotifier: companyCacheBuster$
  })
  createCompany(data): Observable<APIResponse> {
    return this.http.post<APIResponse>(this.companyDomain, data).pipe(catchError(Utility.handleError));
  }

  /* UPDATE */

  editModules(id, data, type: 'add' | 'remove' | 'set'): Observable<APIResponse> {
    return this.http.put<APIResponse>(this.companyDomain + id + '/modules', data, { params: { type: type } }).pipe(catchError(Utility.handleError));
  }

  @CacheBuster({
    cacheBusterNotifier: companyCacheBuster$
  })
  updateCompany(companyId: string, data: { company: Partial<ICompany> }) {
    return this.http.put<APIResponse>(`${this.companyDomain}/${companyId}`, data);
  }

  /* DELETE */

  @CacheBuster({
    cacheBusterNotifier: companyCacheBuster$
  })
  deleteCompany(id): Observable<APIResponse> {
    return this.http.delete<APIResponse>(this.companyDomain + id).pipe(catchError(Utility.handleError));
  }

  /* DELETE */

  deleteUser(id): Observable<APIResponse> {
    return this.http.delete<APIResponse>(this.userDomain + `/${id}`).pipe(catchError(Utility.handleError));
  }
}
