import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { MatSidenav } from '@angular/material';
import { AuthService } from '@app/auth/services/auth.service';
import { Router } from '@angular/router';
import { TdMediaService } from '@covalent/core';
import { PrintService } from '@app/print/print.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'prorec-notifications-sidenav',
  templateUrl: './notifications-sidenav.component.html',
  styleUrls: ['./notifications-sidenav.component.scss']
})
export class NotificationsSidenavComponent implements OnInit {

  @ViewChild('notificationSidenav', { static: true }) set notificationSidenav(notificationSidenav: MatSidenav) {
    if (!notificationSidenav) {
      return;
    }

    this.notificationService.sidenav = notificationSidenav;
  }

  constructor(
    public notificationService: NotificationService,
    public auth: AuthService,
    public router: Router,
    public media: TdMediaService,
    public printService: PrintService
  ) { }

  ngOnInit() {
  }

  checkAndCloseNotificationSidenav(sidenav: MatSidenav) {
    this.media.registerQuery('gt-lg').pipe(
      take(1)
    ).subscribe((enabled: boolean) => {
      if (!enabled) {
        sidenav.close();
      } else {
        return;
      }
    });
  }

}
