import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { UiModule } from '@app/ui/ui.module';
import { FeedbackTriggerComponent } from './feedback-trigger/feedback-trigger.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { FeedbackStartComponent } from './feedback-start/feedback-start.component';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';
import { ArticleComponent } from './article/article.component';
import { FeedbackRoutingModule } from './feedback.routing';
import { FeedbackToolbarComponent } from './feedback-toolbar/feedback-toolbar.component';

@NgModule({
  declarations: [FeedbackDialogComponent, FeedbackTriggerComponent, FeedbackStartComponent, SendFeedbackComponent, ArticleComponent, FeedbackToolbarComponent],
  imports: [
    CommonModule,
    FeedbackRoutingModule,
    UiModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    FeedbackTriggerComponent
  ],
  entryComponents: [FeedbackDialogComponent]
})
export class FeedbackModule { }
