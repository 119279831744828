import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { UiModule } from '@app/ui/ui.module';
import { NotificationsSidenavComponent } from './notifications-sidenav/notifications-sidenav.component';

@NgModule({
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    UiModule
  ],
  declarations: [NotificationsListComponent, NotificationsSidenavComponent],
  exports: [
    NotificationsSidenavComponent
  ]
})
export class NotificationsModule { }
