import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Error404Component } from './error404/error404.component';
import { ModuleGuard } from './auth/guards/module.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('app/start/start.module').then(m => m.StartModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'connect',
    loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Social'
    }
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'CMS'
    }
  },
  {
    path: 'assets',
    loadChildren: () => import('./asset/asset.module').then(m => m.AssetModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Assets'
    }
  },
  {
    path: 'stats',
    loadChildren: () => import('app/stats/stats.module').then(m => m.StatsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Social'
    }
  },
  {
    path: 'digitalsignage',
    loadChildren: () => import('app/digitalsignage/digitalsignage.module').then(m => m.DigitalsignageModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Digital Signage'
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('app/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Inställningar'
    }
  },
  {
    path: 'errands',
    loadChildren: () => import('app/errands/errands.module').then(m => m.ErrandsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Ärenden'
    }
  },
  {
    path: 'project',
    loadChildren: () => import('app/project/project.module').then(m => m.ProjectModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Projekt'
    }
  },
  {
    path: 'notifications',
    loadChildren: () => import('app/notifications/notifications.module').then(m => m.NotificationsModule),
  },
  {
    path: 'leads',
    loadChildren: () => import('app/leads/leads.module').then(m => m.LeadsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Leads'
    }
  },
  {
    path: 'news',
    loadChildren: () => import('app/news/news.module').then(m => m.NewsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Nyheter'
    }
  },
  {
    path: 'inbox',
    loadChildren: () => import('app/inbox/inbox.module').then(m => m.InboxModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Inbox'
    }
  },
  {
    path: 'live-support',
    loadChildren: () => import('app/livesupport/livesupport.module').then(m => m.LivesupportModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Live support'
    }
  },
  {
    path: 'timestamp',
    loadChildren: () => import('app/timestamp/timestamp.module').then(m => m.TimestampModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Stämpelklocka'
    }
  },
  {
    path: 'time-management',
    loadChildren: () => import('app/time-management/time-management.module').then(m => m.TimeManagementModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Tidhantering'
    }
  },
  {
    path: 'registry',
    loadChildren: () => import('app/registry/registry.module').then(m => m.RegistryModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Register'
    }
  },
  {
    path: 'resources',
    loadChildren: () => import('app/resources/resources.module').then(m => m.ResourcesModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Resurshantering'
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('app/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [ModuleGuard],
    data: {
      module: 'Rapporter'
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'oauthcallback',
    redirectTo: '/auth/callback'
  },
  {
    path: '**',
    component: Error404Component,
    data: {
      title: '404'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: [],
})

export class AppRoutingModule { }
