import { Directive, ViewContainerRef, OnInit, Input, TemplateRef, OnChanges } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[appAuthModule]'
})
export class AuthModuleDirective implements OnInit, OnChanges {
  @Input() appAuthModule: string;

  constructor(
    private auth: AuthService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  ngOnInit() {
    if (!this.appAuthModule) {
      this.createView(true);

      return;
      // throw new Error('No module to check access for passed');
    }
  }

  ngOnChanges() {
    if (!this.appAuthModule) {
      return;
    }

    // Create array from string
    const modulesToCheck = this.appAuthModule.split(',');

    // Check all modules
    const hasAccess = modulesToCheck.every((moduleToCheck) => {
      return this.auth.hasAccess(moduleToCheck);
    });

    // Create or clear the view depending on if all auth checks passed
    this.createView(hasAccess);
  }

  createView(hasAccess: boolean) {
    if (hasAccess) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
