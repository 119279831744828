import { OnInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { AuthService } from './auth/services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TdMediaService, TdDialogService } from '@covalent/core';
import { Subject, Observable } from 'rxjs';
import { ModulesService } from './auth/services/modules.service';
import { fadeAnimation } from './animations/animations';
import { takeUntil, take, distinctUntilChanged, switchMap, map, startWith } from 'rxjs/operators';
import { NotificationService } from './notifications/services/notification.service';
import { onlineStatus$ } from './utility/onlineStatus';
import { SwUpdate } from '@angular/service-worker';
import { PrintService } from './print/print.service';
import { ICompany } from './interfaces/company';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('notificationSidenav', { static: true }) set notificationSidenav(notificationSidenav: MatSidenav) {
    if (!notificationSidenav) {
      return;
    }

    this.notificationService.sidenav = notificationSidenav;
  }

  activatedRoute: ActivatedRoute;

  visitorCount$: Observable<number>;

  destroy$: Subject<any> = new Subject<any>();

  navType = 1;

  year = new Date().getFullYear();

  constructor(
    public auth: AuthService,
    private snackbar: MatSnackBar,
    public router: Router,
    public media: TdMediaService,
    public modules: ModulesService,
    public notificationService: NotificationService,
    private swUpdate: SwUpdate,
    private updateService: UpdateService,
    private tdDialog: TdDialogService,
    private moduleService: ModulesService,
    public printService: PrintService
  ) {
    this.updateService.initCheck();

    this.checkForNewAppVersion();
  }

  checkForNewAppVersion() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          return this.tdDialog
            .openConfirm({
              title: 'Ny version tillgänglig!',
              message: 'Det finns en ny version av appen. Ladda om för att hämta den.',
              acceptButton: 'Ladda om',
              cancelButton: 'Avbryt'
            })
            .afterClosed()
            .pipe(
              map((accept: boolean) => accept)
            );
        }),
      ).subscribe((wantsToUpdate) => {
        if (!wantsToUpdate) {
          console.log('Skipped update');

          return;
        }

        this.swUpdate.activateUpdate().then(() => {
          location.reload();
        }).catch((err) => {
          console.error('Could not update:', err);
        });
      });

      this.swUpdate.activated.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.snackbar.open('Du kör nu på senaste versionen av appen!', 'OK', {
          duration: 3000
        });
      });
    }
  }

  ngOnInit() {
    onlineStatus$().pipe(takeUntil(this.destroy$)).subscribe(online => {
      if (!online) {
        this.snackbar.open('Du är offline. Vissa funktioner kan vara otillgängliga', 'OK', {
          horizontalPosition: 'center',
          duration: 5000
        });
      }
    });

    this.auth.loggedIn.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe((loggedIn) => {
      if (loggedIn) {
        this.moduleService.updateModules();

        this.notificationService.init();
      } else {
        this.notificationService.disconnect();
      }
    });
  }

  checkAndCloseNotificationSidenav(sidenav: MatSidenav) {
    this.media.registerQuery('gt-lg').pipe(take(1)).subscribe((enabled: boolean) => {
      if (!enabled) {
        sidenav.close();
      } else {
        return;
      }
    });
  }

  logout() {
    this.snackbar.open('Du är nu utloggad.', '', {
      duration: 3000,
      horizontalPosition: 'center'
    });
    this.auth.logout();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
