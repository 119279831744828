import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrandSelectorDialogComponent } from './errand-selector-dialog/errand-selector-dialog.component';
import { UiModule } from '@app/ui/ui.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LinkifyPipe } from './linkify.pipe';
import { ErrandSelectorComponent } from './errand-selector/errand-selector.component';
import { SearchFieldComponent } from './search-field/search-field.component';
import { ExpanderComponent } from './expander/expander.component';
import { DateIntervalComponent } from './date-interval/date-interval.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { TableComponent } from './table/table.component';
import { PhotoComponent } from './photo/photo.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CmsFilterComponent } from './cms-filter/cms-filter.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';

@NgModule({
  declarations: [
    ErrandSelectorDialogComponent,
    SearchBarComponent,
    LinkifyPipe,
    ErrandSelectorComponent,
    SearchFieldComponent,
    ExpanderComponent,
    DateIntervalComponent,
    FileDropComponent,
    DateTimeInputComponent,
    TableComponent,
    PhotoComponent,
    CmsFilterComponent,
    CompanySelectorComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    NgxDropzoneModule,
    LazyLoadImageModule,
  ],
  entryComponents: [
    ErrandSelectorDialogComponent
  ],
  exports: [
    ErrandSelectorDialogComponent,
    SearchBarComponent,
    LinkifyPipe,
    ErrandSelectorComponent,
    SearchFieldComponent,
    ExpanderComponent,
    DateIntervalComponent,
    FileDropComponent,
    DateTimeInputComponent,
    TableComponent,
    PhotoComponent,
    LazyLoadImageModule,
    CmsFilterComponent,
    CompanySelectorComponent
  ]
})
export class SharedModule { }
