import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { forwardRef, Output, EventEmitter, Input } from '@angular/core';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor {
    @Input() required: boolean;

    _value: T;

    disabled = false;

    @Output() change = new EventEmitter<T>();

    get value(): T {
        return this._value;
    }

    set value(v: T) {
        if (v !== this._value) {
            this._value = v;

            this.onChange(v);

            this.change.next(v);
        }
    }

    writeValue(value: T) {
        this._value = value;

        this.onChange(value);

        this.change.next(value);
    }

    onChange = (_: any) => { };
    onTouched = () => { };

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}

export abstract class MatInputValueAccessor<T> extends AbstractValueAccessor<T> {
    @Input() placeholder: string;
    @Input() appearance: string;
    @Input() hint: string;
}

export function MakeProvider(type: any) {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => type),
        multi: true
    };
}
