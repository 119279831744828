export const environment = {
  production: true,
  domain: 'https://crm-dev.prorec.se/api/',
  websocketDomain: 'https://crm-dev.prorec.se/',
  notificationSocketPath: '/notifications',
  inboxSocketPath: '/inbox',
  chatDomain: 'https://crm-dev.prorec.se/',
  chatSocketPath: '/socket.io',
  interceptorWhitelist: [
    'https://crm-dev.prorec.se/api/auth/login'
  ],
  whitelistedDomains: [
    'crm-dev.prorec.se'
  ],
  facebookAppId: '374208659788251',
  appName: 'Cosmos CRM (DEV)',
};
