import { throwError, Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { generateFileFromMailAttachment } from './files';
import { analyticsreporting_v4 } from 'googleapis';
import { FormControl } from '@angular/forms';
import { take } from 'rxjs/operators';
import { IFBInsightMetric } from '@app/stats/statistics.model';

export const GoogleMetricHeaders = {
  'ga:users': 'Användare',
  'ga:pageviews': 'Sidvisningar',
  'ga:sessions': 'Sessioner',
  'ga:newUsers': 'Nya användare'
};

export const FacebookMetricHeaders = {
  'page_views_total': 'Sidvisningar',
  'page_impressions': 'Intryck'
};

export default class Utility {

  public static generateFileFromMailAttachment = generateFileFromMailAttachment;

  /**
   * Compares two objects by the `_id` property.
   * 
   * Fallback is comparing objects with `===`
   */
  public static compareWithId(a: any, b: any) {
    if (a && a._id && b && b._id) {
      return a._id === b._id;
    } else if ((a && a._id) && (!b || !b._id)) {
      return a._id === b;
    } else if ((!a || !a._id) && (b && b._id)) {
      return a === b._id;
    } else {
      return a === b;
    }
  }

  static getBase64FromFile(file: Blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  public static localeFormat(val: any) {
    const dVal = moment(val).format('ddd DD');

    return dVal;
  }

  public static JSONifyForm(formValues: any, files?: any[]) {
    const formData = new FormData();

    // Append to formData
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        const fVal = formValues[key];
        formData.append(key, JSON.stringify(fVal));
      }
    }

    if (files) {
      files.forEach((el: File) => {
        formData.append('file', el);
      });
    }

    return formData;
  }

  /**
   * For converting FormGroup values to a FormData object
   *
   * @static
   * @param {Object} formValues
   * @param {FormData} [form]
   * @memberof Utility
   */
  public static convertFormToFormData(formValues: Object, form?: FormData) {
    const formData = form || new FormData();

    for (const key in formValues) {
      if (formValues.hasOwnProperty(key)) {
        const element = formValues[key];
        // console.log(key, element);
        if (element instanceof Date) {
          formData.append(key, element.toISOString());
        } else if (element instanceof Array && !(element instanceof File)) {
          formData.append(key, element.join(','));
        } else {
          formData.append(key, element);
        }
      }
    }

    return formData;
  }

  public static convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    const formData = form || new FormData();

    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) {
        continue;
      }
      const formKey = namespace ? `${namespace}_${propertyName}` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.convertModelToFormData(model[propertyName], formData, formKey);
      } else {
        formData.append(formKey, model[propertyName].toString());
      }
    }
    return formData;
  }

  /**
 * Removes duplicates from an array
 *
 * @private
 * @param {*} a The array to filter
 * @returns
 * @memberof ServiceComponent
 */
  public static uniq(a: any) {
    return Array.from(new Set(a));
  }

  public static convertToGraphData(data: any[]) {
    const graphData = [];
    data.forEach((el: any) => {
      const oDate: string = el.dimensions[0];
      const pattern = new RegExp(/(\d{4})(\d{2})(\d{2})/);
      const convertedDate = oDate.replace(pattern, '$1-$2-$3');
      const elData = {
        'name': convertedDate,
        'value': el.metrics[0].values[0]
      };
      graphData.push(elData);
    });
    return graphData;
  }

  public static handleError(error: Response | any) {
    // In real world application, call to log error to remote server
    // logError(error);
    console.error('REQUEST ERROR:', error);

    // This returns another Observable for the observer to subscribe to
    return throwError(error);
  }

  public static convertGoogleAnalyticsToGraphData(reports: any[], ...metricTitleOverrides: string[]) {
    // The data to be returned
    const analyticsGraphData = [];

    // Iterate through all the metrics and convert to the graph format
    reports.forEach((report: analyticsreporting_v4.Schema$Report) => {
      // All the values, separated by dates (also depending on the format requested)
      const reportColumnHeader = report.columnHeader;
      const metricHeaders = reportColumnHeader.metricHeader.metricHeaderEntries;

      const reportData = report.data;
      const metricRows = reportData.rows;

      metricHeaders.forEach((header, index: number) => {
        const translatedMetricTitle = GoogleMetricHeaders[header.name];

        // The converted rows
        const rowData = [];

        // Iterate through the data points
        metricRows.forEach(dataPoint => {
          // In this case this is the date, for now we only support one dimension
          const dataPointDimensions = dataPoint.dimensions[0];
          // The value for the chosen metric, also only support for single value
          const dataPointMetrics = dataPoint.metrics[0].values[index];

          const point = {
            name: moment(dataPointDimensions).startOf('day').toDate(),
            source: 'ga',
            value: Number(dataPointMetrics)
          };

          // Add the converted data point to the new array
          rowData.push(point);
        });

        // Push the converted data to the graph data array
        analyticsGraphData.push({
          name: metricTitleOverrides[index] || translatedMetricTitle || header.name,
          series: rowData
        });
      });

    });

    return analyticsGraphData;
  }

  public static convertInstagramInsightsToGraphData(metricDataObjectArray: any[], ...metricTitleOverride: string[]) {
    // The data to be returned
    const instagramGraphData = [];

    // Iterate through all the metrics and convert to the graph format
    metricDataObjectArray.forEach((metric: IFBInsightMetric, index: number) => {

      // Title for the metric
      const metricTitle = FacebookMetricHeaders[metric.name] || metric.title;

      // All the values, separated by dates (also depending on the format requested)
      const metricValues = metric.values as any[];

      // Metric info
      const metricInfo = metric.description as string;

      // The converted rows
      const rowData = [];

      // Iterate through the data points
      metricValues.forEach(dataPoint => {
        const point = {
          name: moment(dataPoint.end_time).startOf('day').toDate(),
          source: 'ig',
          value: dataPoint.value
        };
        rowData.push(point);
      });

      // Push the converted data to the graph data array
      instagramGraphData.push(
        {
          name: metricTitleOverride[index] || metricTitle,
          description: metricInfo,
          series: rowData
        });
    });

    return instagramGraphData;
  }

  /**
   * Combines an array of ngx-chjarts graph-objects
   *
   * @static
   * @param {{
   *     name: string,
   *     series: {
   *       name: string,
   *       value?: string
   *     }[]
   *   }[]} metricData
   * @param {string} newName
   * @returns An array with all the values combined form all the arrays
   * @memberof Utility
   */
  public static combineGraphArray(metricData: {
    name: string,
    series: {
      name: Date,
      value?: string
    }[]
  }[], newName: string) {
    const combinedData = {
      name: newName,
      series: []
    };

    // Iterate through all the metrics
    metricData.forEach(metric => {
      // Iterathe through the data points
      metric.series.forEach(dataPoint => {
        // Check if we have already added the object to the combinedData object
        // Compare moment objects using moments internal "isSame"
        const indexOfExistingObj = combinedData.series.findIndex(s => {
          // console.log(s.name, dataPoint.name, moment(s.name).isSame(dataPoint.name, 'date'));

          // return s.name === dataPoint.name;
          return moment(s.name).isSame(dataPoint.name, 'date');
        });
        //  If we found a match, add the data point's value to the match found in the combinedData object
        if (indexOfExistingObj > -1) {
          combinedData.series[indexOfExistingObj].value += dataPoint.value;
        } else {
          // Else just add the data point normally
          combinedData.series.push({
            name: dataPoint.name,
            value: dataPoint.value
          });
        }
      });
    });

    // Sort function for the dates
    function sortFn(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }

    // Sort by date (data point "name" property)
    combinedData.series.sort(sortFn);

    // We need to return an array for ngx-charts to be able to read it
    return [combinedData];
  }

  /**
   * For use with Mat Select filtering
   *
   * @param obs Observable to get data from and filter from
   * @param filterControl Form control to get current value fom
   * @param filteredArray Array to apply the filtering to
   * @param filterFunction Which filtering technique to use
   */
  static filter(obs: Observable<any>, filterControl: FormControl, filteredArray: BehaviorSubject<any[]>, filterFunction: Function) {
    obs.pipe(take(1)).subscribe(_unfilteredArray => {
      let search: string = filterControl.value;

      if (!search) {
        filteredArray.next(_unfilteredArray);

        return;
      } else {
        search = search.toLowerCase();
      }


      filteredArray.next(filterFunction(_unfilteredArray, search));
    });
  }

  /**
   * Performs a simple search by the key value
   *
   * Supports nested keys, using key.anotherKey.finalKey
   */
  static simplePropertyFiltering(propertyKeyToSearch: string) {
    return function (arrayToFilter: any[], searchParam: string) {
      return arrayToFilter.filter(_o => (_o[propertyKeyToSearch] as string).toLowerCase().indexOf(searchParam) > -1);
    };
  }

}
