import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { APIResponse } from '@app/interfaces/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  readonly domain = `${environment.domain}feedback`;

  constructor(
    private http: HttpClient
  ) { }

  sendFeedback(feedbackData: any) {
    return this.http.post<APIResponse>(this.domain, feedbackData);
  }
}
