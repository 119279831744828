import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';

interface CMSRequest {
  type: 'facilities' | 'forms' | 'services' | 'schedules' | 'sliders' | 'news' | 'serviceCategories' | 'config' | 'campaigns';
  query?: any;
}

interface CMSResourceRequest extends CMSRequest {
  id: string;
}

interface CMSUpdateRequest extends CMSRequest {
  id: string;
  body: any;
}

interface CMSCreateRequest extends CMSRequest {
  body: any;
}

@Injectable({
  providedIn: 'root'
})
export class CmsService {

  readonly domain = environment.domain + 'cms';

  protected companyIdSource: Subject<string> = new Subject();

  public get companyId$() {
    return this.companyIdSource.asObservable();
  }

  protected _companyId: string;

  public get companyId(): string {
    return this._companyId;
  }

  public set companyId(v: string) {
    this._companyId = v;

    this.companyIdSource.next(v);

    sessionStorage.setItem('cms-company', JSON.stringify(v));
  }

  constructor(
    private api: ApiService
  ) {
    const cmsCompany = JSON.parse(sessionStorage.getItem('cms-company'));

    if (cmsCompany) {
      this.companyId = cmsCompany;
    }
  }

  getAll(options: CMSRequest) {
    return this.api.get({
      url: `${this.domain}/${options.type}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
    });
  }

  getbyId(options: CMSResourceRequest) {
    return this.api.get({
      url: `${this.domain}/${options.type}/${options.id}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
    });
  }

  update(options: CMSUpdateRequest) {
    return this.api.put({
      url: `${this.domain}/${options.type}/${options.id}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
      body: options.body,
    });
  }

  upsert(options: CMSCreateRequest) {
    return this.api.put({
      url: `${this.domain}/${options.type}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
      body: options.body,
    });
  }

  create(options: CMSCreateRequest) {
    return this.api.post({
      url: `${this.domain}/${options.type}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
      body: options.body,
    });
  }

  deleteById(options: CMSResourceRequest) {
    return this.api.delete({
      url: `${this.domain}/${options.type}/${options.id}`,
      query: {
        companyId: this.companyId,
        ...options.query
      },
    });
  }
}
