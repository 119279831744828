<div class="app-wrapper" fxLayout="column" ngClass.xs="mobile">
  <div class="header" fxLayoutAlign="center center" fxFlex="none" *ngIf="showTop">
    <div fxLayout="column">
      <h3 class="title">{{headerTitle}}</h3>

      <p class="body">{{headerBody}}</p>
    </div>

    <span fxFlex></span>

    <button mat-icon-button (click)="dropTop()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="content" [ngClass.gt-xs]="{'content-padding': usePadding}" ngClass="content-mobile" fxFlex
    fxLayout="column">
    <ng-content></ng-content>
  </div>
</div>
