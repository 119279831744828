import { Component, OnInit } from '@angular/core';
import { MatExpansionPanelState, matExpansionAnimations } from '@angular/material/expansion';
import { CdkAccordionItem } from '@angular/cdk/accordion';

@Component({
  selector: 'app-expander',
  templateUrl: './expander.component.html',
  styleUrls: ['./expander.component.scss'],
  animations: [
    matExpansionAnimations.bodyExpansion
  ]
})
export class ExpanderComponent extends CdkAccordionItem implements OnInit {

  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  ngOnInit() {
  }

}
