import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BodyInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if it has a body
        if (!req.body) {
            return next.handle(req);
        }

        // Clone the request
        const reqClone = req.clone({
            setHeaders: {
                'ngsw-bypass': 'true'
            }
        });

        // Return
        return next.handle(reqClone);
    }
}
