import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  @Input() headerTitle = 'Titel';
  @Input() headerBody: string;
  @Input() usePadding = true;

  showTop = false;

  constructor() { }

  ngOnInit() {
    this.showTop = this._getShowStatus(this.headerTitle);
  }

  private _getShowStatus(title: string) {
    const status = localStorage.getItem(`tutorial_${title.toLowerCase()}`);

    if (!status) {
      return true;
    }

    return status === 'true';
  }

  dropTop() {
    this.showTop = false;
    localStorage.setItem(`tutorial_${this.headerTitle.toLowerCase()}`, String(this.showTop));
  }

}
