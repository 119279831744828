import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { environment } from '@env/environment';
import { resolve } from 'url';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly userDomain = resolve(environment.domain, 'users/');

  constructor(private api: ApiService) { }

  getUsers(limit: number, page: number) {
    return this.api.get({
      url: this.userDomain,
      query: {
        limit,
        page
      }
    });
  }

  getProfile() {
    return this.api.get({
      url: this.userDomain + 'me'
    });
  }

  getUser(userId: string, query?: any) {
    return this.api.get({
      url: this.userDomain + userId,
      query
    });
  }

  sendPasswordResetLink(userId: string) {
    return this.api.post({
      url: this.userDomain + 'resetPassword/' + userId,
      body: {
        userId
      }
    });
  }

  createUser(body: any) {
    return this.api.post({
      url: this.userDomain,
      body
    });
  }

  updateUser(userId: string, body: any) {
    return this.api.put({
      url: this.userDomain + userId,
      body
    });
  }

  updateProfile(body: any) {
    return this.api.put({
      url: this.userDomain + 'me',
      body
    });
  }

  deleteUser(userId: string) {
    return this.api.delete({
      url: this.userDomain + userId,
    });
  }
}
